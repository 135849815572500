<template>
  <div class="problemFeedback">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="problemFeedback-bach"
      @click.stop="back()"
    />
    <div class="problemFeedback-content">
      <div class="problemFeedback-content-title">问题反馈</div>
      <div class="problemFeedback-content-input flex flex-between">
        <div class="problemFeedback-content-input-text flex">
          <div>您的注册邮箱号：</div>
          <div class="text-text">{{ suerInfo.mail }}</div>
        </div>
        <div class="problemFeedback-content-input-text flex">
          <div>您的UID：</div>
          <div class="text-text">{{ suerInfo.userId }}</div>
        </div>
      </div>
      <div class="problemFeedback-content-input-text mato">您的问题描述：</div>
      <div class="problemFeedback-content-textfield">
        <el-input
          type="textarea"
          :autosize="{ minRows: 10, maxRows: 60 }"
          placeholder="请输入内容"
          v-model="content"
        >
        </el-input>
      </div>
      <div class="problemFeedback-content-img flex">
        <div>
          <el-upload
            action="https://server.bubbleplan.cn/api/v1.0/common/fileUpload"
            :headers="{
              token: username.token,
            }"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
            :limit="3"
            ref="upload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
        <div class="problemFeedback-content-img-text">
          最多上传3张图片 （图片大小1M以内）
        </div>
      </div>
      <!-- <div class="problemFeedback-content-button">确认</div> -->
      <div class="problemFeedback-content-button">
        <el-button type="success" :disabled="isDisabled" @click="countersign()"
          >确认</el-button
        >
      </div>

      <div class="problemFeedback-content-bottom flex flex-between">
        <img
          src="https://cdn.bubbleplan.cn/static/home/bottom_bgc.png"
          alt=""
          class="problemFeedback-content-bottom-img"
        />
        <div class="problemFeedback-content-bottom-text">泡泡计划运营团队</div>
      </div>
    </div>
  </div>
</template>
<script >
export default {
  data() {
    return {
      content: "",
      dialogImageUrl: "",
      dialogVisible: false,
      isDisabled: false,
      fileList: [],
      imageUrl: [],
    };
  },

  methods: {
    async countersign() {
      if (!this.content) {
        this.$message.error("请输入问题描述");
        return;
      }
      const str = this.imageUrl.join(", ");
      const params = {
        content: this.content,
        img: str,
      };
      const { data, code } = await this.$ajax.problemFeedback(params);

      if (code == 200) {
        this.content = "";
        this.imageUrl = [];
        this.$refs.upload.clearFiles();
      }
    },
    handleAvatarSuccess(res, file) {
      console.log(res, 12121);
      if (res.code == 200) {
        this.imageUrl.push(res.msg);
      }

      //   this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type == "image/jpeg" || file.type === "image/png";
      //   const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return isJPG && isLt2M;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.problemFeedback {
  width: 100%;
  height: calc(100vh - 94px);
  background: #021521;
  position: relative;
  padding-top: 40px;
  overflow: auto;
  padding-bottom: 44px;
  &-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 74px;
    left: 90px;
    z-index: 9;
  }
  &-bach:hover {
    cursor: pointer;
  }
  &-content {
    margin: auto;
    width: 1165px;
    background: #13212f;
    border-radius: 20px;
    padding: 0 50px;
    &-title {
      color: #ffffff;
      text-align: center;
      font-size: 21px;
      line-height: 91px;
      border-bottom: 2px solid #021521;
    }
    &-input {
      margin-top: 39px;
      &-text {
        color: #64849a;
        font-size: 20px;
        line-height: 60px;
        .text-text {
          width: 353px;
          height: 60px;
          flex-shrink: 0;
          border-radius: 10px;
          border: 2px solid #4a575f;
          padding: 0 20px;
          margin-left: 10px;
        }
      }
    }
    &-textfield {
      margin-top: 22px;
      :deep(.el-textarea) {
        // border: 2px solid #4a575f;
        border-radius: 10px;
      }
      :deep(.el-textarea__inner) {
        background: #13212f;
        border: 2px solid #4a575f;
        font-size: 20px;
        color: #fff;
      }
    }
    &-img {
      margin-top: 32px;
      :deep(.el-upload--picture-card) {
        width: 101px;
        height: 101px;
        background: url("https://cdn.bubbleplan.cn/static/img/img_add.png")
          no-repeat;
        background-size: 100% 100%;
        border: 0 dashed #c0ccda;
      }
      :deep(.el-icon-plus) {
        font-size: 0;
      }
      :deep(.el-upload-list--picture-card .el-upload-list__item) {
        width: 101px;
        height: 101px;
      }
      &-text {
        color: #495f6f;
        text-align: center;
        font-family: "Source Han Sans SC";
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.7px;
        // letter-spacing: 0.38px;
        line-height: 101px;
        margin-left: 10px;
      }
    }
    &-button {
      width: 1065.53px;
      height: 70px;
      flex-shrink: 0;
      border-radius: 10px;
      //   background: #daff0066;
      margin-top: 42px;
      line-height: 70px;
      text-align: center;
      color: #13212f;
      font-size: 22px;
      :deep(.el-button--small) {
        width: 100%;
        height: 100%;
        font-size: 22px;
        // line-height: 70px;
      }
      :deep(.el-button--success.is-disabled) {
        background: #daff0066;
        border-color: #daff0066;
      }
    }
    &-bottom {
      width: 100%;
      border-top: 1px solid rgba(168, 183, 195, 0.2);
      margin-top: 53px;
      &-img {
        width: 654px;
        height: 66px;
      }
      &-text {
        color: #5e6c79;
        font-size: 20px;
        line-height: 66px;
      }
    }
  }
  .mato {
    margin-top: 35px;
  }
}
</style>